import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs';
import { IFooter, IFooterControl } from 'src/app/api/modules/core/components/static/IFooter';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { FooterModel } from './footer.model';

/**
 * The Default State
 */
function initialState(): FooterModel {
  return {
    configure: null,
    hidden: null,
    appLayoutClass: '',
  };
}

/**
 * The Store used for a {@link FooterComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class FooterRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `footer`,
    },
    withProps<FooterModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Configures the Footer State.
   */
  applyConfiguration(configuration?: IFooter) {
    // the app layout class
    let appLayoutClass: '' | 'footer-1' | 'footer-2' | 'footer-3' = this.store.getValue().appLayoutClass;

    // if there is a configure property, use it to set the state of the footer
    let configure = this.store.getValue().configure;
    if (configuration?.configure) {
      configure = cloneDeep(configuration.configure);
    }

    // if there is a hidden property set it otherwise all hidden is cancelled
    let hidden: boolean | IFooterControl = this.store.getValue().hidden;
    if (configuration?.hidden === true) {
      hidden = true;
    } else if (configuration?.hidden === false) {
      hidden = false;
    } else if (typeof configuration?.hidden === 'object') {
      hidden = cloneDeep(configuration.hidden);
    }

    // if the draft has a configuration
    if (configure) {
      // count how many active items so that the app can be informed of optimised padding
      let activeCount = 0;

      if (configure.branding) {
        if (hidden) {
          if (hidden === true || (typeof hidden === 'object' && hidden.branding === true)) {
            // this is not an active item
          } else {
            activeCount++;
          }
        } else {
          activeCount++;
        }
      }

      if (configure.sharing) {
        if (hidden) {
          if (hidden === true || (typeof hidden === 'object' && hidden.sharing === true)) {
            // this is not an active item
          } else {
            activeCount++;
          }
        } else {
          activeCount++;
        }
      }

      if (configure.termsConditions) {
        if (hidden) {
          if (hidden === true || (typeof hidden === 'object' && hidden.termsConditions === true)) {
            // this is not an active item
          } else {
            activeCount++;
          }
        } else {
          activeCount++;
        }
      }

      // depending on how many items, the correct class is specified
      switch (activeCount) {
        default:
          appLayoutClass = 'footer-3';
          break;
        case 0:
          appLayoutClass = '';
          break;
        case 1:
          appLayoutClass = 'footer-1';
          break;
        case 2:
          appLayoutClass = 'footer-2';
          break;
      }
    } else {
      configure = null;
      hidden = true;
      appLayoutClass = '';
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configure = configure;
        state.hidden = hidden;
        state.appLayoutClass = appLayoutClass;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * What layout class should be used?
   */
  appLayoutClass$ = this.store.pipe(select((state) => state.appLayoutClass));

  /**
   * Is the Footer Configured?
   */
  private _configure$ = this.store.pipe(select((state) => state.configure));

  /**
   * Is the Footer Hidden?
   */
  private _hidden$ = this.store.pipe(select((state) => state.hidden));

  /**
   * The Footer Sharing Object
   */
  sharing$ = ElfCombineQueries([this._configure$, this._hidden$]).pipe(
    map(([configure, hidden]) => {
      // Extract sharing from configure if exists is true and configure is defined
      if (configure?.sharing) {
        const isHidden = typeof hidden === 'object' ? hidden.sharing : hidden;
        // Return null if sharing should be hidden, otherwise return the sharing config
        return isHidden ? null : configure.sharing;
      }
      // Return null if initial conditions are not met
      return null;
    }),
  );

  /**
   * The Footer Branding Object
   */
  branding$ = ElfCombineQueries([this._configure$, this._hidden$]).pipe(
    map(([configure, hidden]) => {
      // Extract branding from configure if exists is true and configure is defined
      if (configure?.branding) {
        const isHidden = typeof hidden === 'object' ? hidden.branding : hidden;
        // Return null if branding should be hidden, otherwise return the branding config
        return isHidden ? null : configure.branding;
      }
      // Return null if initial conditions are not met
      return null;
    }),
  );

  /**
   * The Footer Terms and Conditions Object
   */
  termsConditions$ = ElfCombineQueries([this._configure$, this._hidden$]).pipe(
    map(([configure, hidden]) => {
      // Extract termsConditions from configure if exists is true and configure is defined
      if (configure?.termsConditions) {
        const isHidden = typeof hidden === 'object' ? hidden.termsConditions : hidden;
        // Return null if termsConditions should be hidden, otherwise return the termsConditions config
        return isHidden ? null : configure.termsConditions;
      }
      // Return null if initial conditions are not met
      return null;
    }),
  );

  /**
   * Should the Footer be shown?
   */
  showFooter$ = ElfCombineQueries([this.sharing$, this.branding$, this.termsConditions$, this._hidden$]).pipe(
    map(([sharing, branding, termsConditions, hidden]) => {
      // if there is a hidden property set to true then the footer should not be shown
      if (hidden === true) {
        return false;
      }

      // if any of the footer items are not hidden
      if (sharing || branding || termsConditions) {
        return true;
      }

      return false;
    }),
  );

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([this.showFooter$, this.sharing$, this.branding$, this.termsConditions$]).pipe(
    map(([showFooter, sharing, branding, termsConditions]) => ({ showFooter, sharing, branding, termsConditions })),
  );
}
