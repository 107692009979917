export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '6.0.30',
    name: '@digitaservice/app',
    versionDate: '2025-04-02T08:31:16.147Z',
    gitCommitHash: 'ddbd764a6',
    versionLong: '6.0.30-ddbd764a6',
};
export default versions;
